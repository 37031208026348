// import * as React from 'react';
import { useEffect, useRef, useState, useCallback } from 'react';

import Head from 'next/head'
// import Link from 'next/link'
import Navbar from 'components/navbar'

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


// import { serverSideTranslations } from "next-i18next/serverSideTranslations";
// import { useTranslation } from 'react-i18next';
// export async function getServerSideProps({locale,query}){
//   return{
//     props:{
//       query,
//       ...(await serverSideTranslations(locale,['common']))
//     },
//   }
// }

function PageNotFound(props){
  // const {t} =  useTranslation();

  return(
    <>
      <Head>
      {/* <title>{t('common:not_found')} | {t('common:site_title')}</title> */}
      <title>Page not found | Jotun Performance Collection</title>
        <meta name="description" content="" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      
      <Navbar/>
      
      <main className="common_main">
        <Container maxWidth='xs'>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} component="section">
            <Grid item xs={10} sx={{
                '& .MuiTypography-h6': { mb: 3,lineHeight:"1.3" },
                '& .MuiTypography-body1': { mb: 4 }
              }}>
                {/* <Typography variant="h6" gutterBottom component="h1" align="center" dangerouslySetInnerHTML={{__html: t('privacy_policy:title')}}> */}

                <Typography variant="h2" gutterBottom component="h1" align="center">
                  404
                </Typography>
                {/* <div className={styles.body_text} dangerouslySetInnerHTML={{__html: t('privacy_policy:body1')}}> */}
                <Typography className="body_text" variant="h6" align="center" sx={{
                  '& p': {textAlign: "center"}
                  }}>
                  {/* <p>{t('common:not_found')}</p> */}
                Page not found
                </Typography>
            </Grid>
          </Grid>
        </Container>
      
      </main>
      
    </>
  )
}
export default PageNotFound;